import React from 'react'
import Layout, { Container } from 'src/components/Layout'
import { Helmet } from 'react-helmet'
import { Title } from 'src/components/typography'
import styled from '@emotion/styled'
import peg from 'src/pegTheme'
import currentScreenshot from 'src/images/current-screenshot.png'

export default () => (
  <Layout>
    <Helmet>
      <title>Current | YouTube & Instagram Influencer Marketing Tool & Platform | Peg</title>
      <meta name='description' content='Learn more about our 3 founders and what inspired them to create the most complete YouTube & Instagram influencer marketing and analytics tool' />
    </Helmet>

    <AboutContainer>
      <Title>Peg + Current</Title>
      <CurrentScreen src={currentScreenshot} />

      <AboutText>In 2021, Peg's technology was acquired by <a href='http://current.tech/'>Current</a>, one of the top influencer platforms. Being part of their family means instant access to many more valuable tools and features such as influencer payment and in-platform messaging.</AboutText>

      <AboutText>To take advantage of Peg's <a href='https://influencermarketinghub.com/peg/'>top rated</a> tech with access to an even bigger suite of influencer marketing software, please contact <a href='mailto:info@current.tech'>info@current.tech</a> (and mention that you came to them via Peg!)</AboutText>
      
      <AboutText>— Nic, Julia, Alex and team</AboutText>
    </AboutContainer>
  </Layout>
)

const AboutContainer = styled(Container)`
  align-items: center;
  padding-bottom: ${ peg.spacing('xl') };
`

const CurrentScreen = styled.img`
  width: 100%;
  max-width: 800px
`

const AboutText = styled.p`
  margin-top: 0;
  max-width: 800px;
  padding: 0 ${ peg.spacing('m') };
`